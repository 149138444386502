import alxCert from "../assets/pictures/alx-cert.png";
import awsCcpCert from "../assets/pictures/aws-ccp-cert.png";
import awsSaaCert from "../assets/pictures/aws-saa-cert.png";
import freecodecampCert from "../assets/pictures/freecodecamp-cert.png";
import gcpPcaCert from "../assets/pictures/gcp-pca-cert.png";
import gcpSkillshareCert from "../assets/pictures/gcp-skillshare-cert.png";
import udacityNanodegreeCert from "../assets/pictures/udacity-nanodegree-cert.png";

export const achievementData = {
  bio: "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
  achievements: [
    {
      id: 1,
      title: "GCP Professional Cloud Architect",
      details: "Google Cloud Platform Certification",
      date: "Oct, 2024",
      field: "Cloud Computing",
      image: gcpPcaCert,
    },
    {
      id: 2,
      title: "Introduction to Generative AI",
      details: "Google Cloud Skills Boost Certification",
      date: "Aug, 2024",
      field: "Cloud Computing",
      image: gcpSkillshareCert,
    },
    {
      id: 3,
      title: "ALX Cloud Computing",
      details: "ALX Africa Certification",
      date: "May, 2024",
      field: "Cloud Computing",
      image: alxCert,
    },
    {
      id: 4,
      title: "AWS Certified Solutions Architect - Associate",
      details: "Amazon Web Services Certification",
      date: "Feb, 2024",
      field: "Cloud Computing",
      image: awsSaaCert,
    },
    {
      id: 5,
      title: "AWS Certified Cloud Practitioner",
      details: "Amazon Web Services Certification",
      date: "Oct, 2023",
      field: "Cloud Computing",
      image: awsCcpCert,
    },
    {
      id: 6,
      title: "Back End Development and APIs",
      details: "freeCodeCamp Certification",
      date: "Aug, 2023",
      field: "Software Engineering",
      image: freecodecampCert,
    },
    {
      id: 7,
      title: "Programming for Datascience",
      details: "Udacity Nanodegree",
      date: "Aug, 2021",
      field: "Data Science, SQL",
      image: udacityNanodegreeCert,
      //     "https://udemy-certificate.s3.amazonaws.com/image/UC-8f5ff8e8-b6a4-4efd-a3fc-7fb63b29e77f.jpg?v=1614489452000",
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
