import resume from '../assets/pdf/resume.pdf'
import image2 from "../assets/pictures/profile2.png";


export const headerData = {
    name: 'Faithful Olaleru',
    title: "Software Engineer",
    desciption:"Don’t be afraid to fail big, to dream big. If you can dream it, you can do it. No one has ever achieved greatness without dreams.",
    image: image2,
    resumePdf: resume
}
