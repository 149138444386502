export const experienceData = [
    {
        id: 1,
        company: 'Xcene Research',
        jobtitle: 'Senior Software Engineer',
        startYear: 'August 2023',
        endYear: 'present'
    },
    {
        id: 2,
        company: 'Turog Integrated Solutions',
        jobtitle: 'Java Backend Engineer',
        startYear: 'February 2023',
        endYear: 'August 2023'
    },
    {
        id: 3,
        company: 'Nupat Technologies',
        jobtitle: 'Outsourced Backend Engineer',
        startYear: 'February 2023',
        endYear: 'August 2023'
    },
    {
        id: 4,
        company: 'Sabi.am Africa',
        jobtitle: 'Java Backend Engineer',
        startYear: 'January 2022',
        endYear: 'October 2022'
    },
    {
        id: 5,
        company: 'Energyday Nigeria',
        jobtitle: 'Webmaster',
        startYear: 'June 2021',
        endYear: 'December 2021'
    },
    {
        id: 6,
        company: 'CITS, University of Lagos',
        jobtitle: 'Fullstack Engineer',
        startYear: 'September 2018',
        endYear: 'May 2021'
    },
    // {
    //     id: 7,
    //     company: 'Genomics Center for Non-Communicable Diseases',
    //     jobtitle: 'Web Administrator',
    //     startYear: 'March 2020',
    //     endYear: 'January 2021'
    // },
]